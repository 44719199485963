import React, { useRef, useState, useEffect } from "react";
import "./App.css";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDXLbfZB-bOJMuuXA3bectF1TFCLRpqSzQ",
  authDomain: "live-check-5f752.firebaseapp.com",
  projectId: "live-check-5f752",
  storageBucket: "live-check-5f752.appspot.com",
  messagingSenderId: "235968955480",
  appId: "1:235968955480:web:b06084b96706e338eeb62d",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = firebase.auth();
const firestore = firebase.firestore();
const analytics = firebase.analytics();

function App() {
  const [user] = useAuthState(auth);

  return (
    <div className="App">
      <header>
        <h1>Moonshine App 🌞🌛</h1>
      </header>

      <section>{user ? <ChatRoom /> : <SignIn />}</section>
    </div>
  );
}

function SignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };

  return (
    <>
      <button className="sign-in" onClick={signInWithGoogle}>
        Sign in with Google
      </button>
    </>
  );
}

function SignOut() {
  return (
    auth.currentUser && (
      <button className="sign-out" onClick={() => auth.signOut()}>
        Sign Out
      </button>
    )
  );
}

function ChatRoom() {
  const dummy = useRef();
  const messagesRef = firestore.collection("messages");
  const query = messagesRef.orderBy("createdAt").limit(50);

  const [messages] = useCollectionData(query, { idField: "id" });
  const [formValue, setFormValue] = useState("");
  const [canSendMessage, setCanSendMessage] = useState(true);

  useEffect(() => {
    const checkSendMessagePermission = async () => {
      const { uid } = auth.currentUser;
      const lastMessage = await messagesRef
        .where("uid", "==", uid)
        .orderBy("createdAt", "desc")
        .limit(1)
        .get();

      if (lastMessage.docs.length > 0) {
        const lastMessageData = lastMessage.docs[0].data();
        const currentTime = Date.now();
        const lastMessageTime = lastMessageData.createdAt.toMillis();
        const timeDifference = currentTime - lastMessageTime;
        const hoursPassed = Math.floor(timeDifference / (1000 * 60 * 60));

        setCanSendMessage(hoursPassed >= 24);
      }
    };

    checkSendMessagePermission();
  }, []);

  const sendMessage = async (e) => {
    e.preventDefault();

    if (!canSendMessage) {
      window.alert("You can only send one message every 24 hours.");
      return;
    }

    const { uid, photoURL } = auth.currentUser;

    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL,
    });

    setFormValue("");
    dummy.current.scrollIntoView({ behavior: "smooth" });
    setCanSendMessage(false);

    setTimeout(() => {
      setCanSendMessage(true);
    }, 24 * 60 * 60 * 1000); // Reset the send message permission after 24 hours
  };

  return (
    <>
      <main>
        {messages &&
          messages.map((msg) => <ChatMessage key={msg.id} message={msg} />)}
        <span ref={dummy}></span>
      </main>

      <form onSubmit={sendMessage}>
        <input
          value={formValue}
          onChange={(e) => setFormValue(e.target.value)}
          placeholder="Say something nice"
        />
        <button type="submit" disabled={!formValue || !canSendMessage}>
          🕊️
        </button>
      </form>
    </>
  );
}

function ChatMessage(props) {
  const { text, uid, photoURL } = props.message;
  const messageClass = uid === auth.currentUser.uid ? "sent" : "received";

  return (
    <>
      <div className={`message ${messageClass}`}>
        <img
          src={
            photoURL || "https://api.adorable.io/avatars/23/abott@adorable.png"
          }
          alt="User"
        />
        <p>{text}</p>
      </div>
    </>
  );
}

export default App;
